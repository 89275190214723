.mail{
    padding: 10px 0 50px;
    input[type="email"], textarea{
        font-size: 16px;
        padding: 5px 10px;
    }
    [type="text"]{
        padding: 5px 44px 5px 10px;
        font-size: 16px;
    }
    .wrapper{
        padding: 10px 1.5rem;
        margin: 0 auto;
        max-width: 720px;
        form{
            .btnw{
                display: flex;
                justify-content: center;
                margin: 30px auto 20px;
            }
            h4{
                font-size: 16px;
                font-weight: 400;
                margin: 5px auto 15px;
            }
            input[type="submit"], button[type="submit"]{
                font-size: 16px;
                padding: 10px 24px;
                background: transparent;
                border-radius: 11px;
                margin: 0 auto;
                border: 2.5px solid rgb(7, 5, 22);
                transition: all .2s linear;
                &:hover{
                    background: rgb(7, 5, 22);
                    color: #f0f0f0;
                }
            }
            .input_wrapper{
                margin: 10px auto 20px;
                input{
                    display: block;
                    width: 100%;
                }
            }
            textarea{
                display: block;
                width: 100%;
                resize: vertical;
                min-height: 200px;
                margin: 20px 0 40px;
            }
            .input_wrapper{
                .flex{
                    display: flex;
                    position: relative;
                    .select{
                        position: absolute;
                        right: 0;
                        // width: 0;
                    }
                }
            }
            .radio_wrapper{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                // max-width: 600px;
                gap: 20px;
                // position: relative;
                input[type="radio"]{
                    position: absolute;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    
                }
                input[type="radio"]:checked + label{
                    background: #9797978a;
                }
                .card{
                    background: #efeded8c;
                    border-radius: 20px;
                    box-shadow: 0px 5px 5px 1px #adadad5e;
                    width: 30%;
                    transition: all .2s linear;
                    &:hover{
                        background: #dfdfdfde;
                    }
                    .wrapper_card{
                        padding: 5px 10px 0;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        padding-bottom: 12px;
                        // width: 180px;
                        h3{
                            margin-bottom: 10px;
                        }
                        img{
                            width: 100%;
                            height: 100px;
                            display: block;
                            object-fit: contain;
                            border-radius: 20px;
                            background: #eaeeea;
                        }
                        >div {
                            width: 100%;
                            height: 100px;
                            border-radius: 20px;
                            background: #eaeeea;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        svg{
                            font-size: 54px;
                        }
                    }
                }
            }
            
        }
    }
}

@media (max-width: 980px) {
    .card{
        width: 48% !important;
    }
}

@media (max-width: 850px) {
    .card{
        width: 100% !important;
    }
}