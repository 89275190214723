.background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    background: transparent;
}
.sidebar{
    z-index: 100000;
    max-width: 300px;
    border-right: 2px solid rgba(221, 218, 218, 0.75);
    width: 100%;
    min-height: 100vh;
    height: auto;
    color: rgb(63, 63, 201);
    overflow-x: hidden;
    background: white;
    transition: all .2s;
    .wrapper{
        display: flex;
        flex-direction: column;
        .logo{
            font-size: 22px;
            font-weight: 600;
            text-align: center;
            padding: 12px 0;
            border-bottom: 2px solid rgba(221, 218, 218, 0.75);
        }
        >nav{
            >ul{
                >h3{
                    padding: 18px 10px 5px;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: rgb(134, 134, 134);
                }
                li{
                    list-style: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 6px 26px 6px;
                    transition: linear .1s;
                    &:hover{
                        color: rgb(119, 119, 228);
                        background: rgba(223, 223, 223, 0.5);
                    }
                }                
            }
        }
    }
}

.navbar{
    display: block;
    width: 100%;
    padding: 11px 17px 10px 7px;
    border-bottom: 2px solid rgba(221, 218, 218, 0.75);
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user{
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            .logo{
                margin-right: 5px;
                overflow: hidden;
                border-radius: 50%;
                border: 2px solid rgba(221, 218, 218, 0.75);
                width: 36px;
                height: 36px;
                img{
                    display: inline;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .name{
                margin-right: 10px;
            }
            .role{
                font-size: 12px;
                margin-top: -7px;
                color: rgb(119, 119, 119);
            }
        }
        .burger{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 28px;
            height: 20px;
            cursor: pointer;
            opacity: 0;
            span{
                display: block;
                width: 100%;
                background: black;
                height: 4px;
                border-radius: 2px;
            }
        }
    }
}
@media (max-width: 700px) {
    .sidebar{
        position: absolute;
        left: -100%;
        &[data-active="true"]{
            left: 0;
        }
    }
    .burger{
        opacity: 1 !important;
    }
    
}