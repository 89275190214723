@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500&display=swap');
body, html{
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    font-size: 16px;
    width: 100%;
    scroll-behavior: smooth;
    overflow: hidden;
}
textarea{
    font-family: 'Roboto', sans-serif;
}
a{
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
  
*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.5;
}
.layout{
    display: flex;
    position: relative;
    z-index: 9999;
    height: 100vh;
    // flex-wrap: wrap;
    .left_col{
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto; 
        main{
            overflow-y: auto;
        }
    }
}
.link{
    color: rgb(70, 101, 170);
    transition: all .13s linear;
    &:hover{
        color: rgb(89, 123, 199);
        text-decoration:underline;
    }
}

nav{
    .active{
        li{
            background: rgb(216, 216, 216);
        }
    }
}